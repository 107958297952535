import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { Box, Icon, Tab, Tabs } from '@mui/material';

import CampaignsFanHub from '../components/campaigns-page/CampaignsFanHub';
import CampaignsPlaylists from '../components/campaigns-page/CampaignsPlaylists';
import CampaignsSocialMedia from '../components/campaigns-page/CampaignsSocialMedia';
import ScrollToTopButton from '../components/utility/buttons/ScrollToTopButton';
import UserHeader from '../components/utility/navigation/UserHeader';

const CampaignsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (location.state) {
      setValue(location.state);
    }
  }, [location]);

  return (
    <div data-testid="campaigns-page" className="page-content">
      <UserHeader title="PAGE-TITLES.CAMPAIGNS" />
      <ScrollToTopButton />
      <Box
        sx={{
          width: '100%',
          marginTop: '20px',
          marginBottom: '8px',
        }}
      >
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
          <Tab label={t('CAMPAIGNS-PAGE.SOCIAL-MEDIA')} data-testid="campaigns-social-media" />
          <Tab label={t('CAMPAIGNS-PAGE.PLAYLISTS')} data-testid="campaigns-playlists" />
          <Tab label={t('HOMEPAGE.FAN-HUB')} data-testid="campaigns-fan-hub" />
          <Tab
            className="btn-white ml-auto mr0"
            label={
              <p className="d-flex small pt0">
                <Icon>add</Icon>
                <span className="pl8 mt-auto mb-auto small">{t('CAMPAIGNS-PAGE.NEW-CAMPAIGN')}</span>
              </p>
            }
            data-testid="campaigns-fan-hub"
          />
        </Tabs>
      </Box>
      {value === 0 && <CampaignsSocialMedia />}
      {value === 1 && <CampaignsPlaylists />}
      {value === 2 && <CampaignsFanHub />}
      {value === 3 && <Navigate to={'/promote'} />}
    </div>
  );
};

export default CampaignsPage;
